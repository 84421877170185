<template>
  <div>
    <h2 class="jsmb-title-detail has-text-text is-size-4">Mes Messages</h2>
    <div>
      <div class="column message message-container">
        <div class="box message-preview" :class="message.user.role" v-for="(message, index) in messages" :key="'message-'+index">
          <div class="top">
            <div class="message-head">
              <div class="avatar">
                <img :src="message.user.profil_picture!==null?message.user.profil_picture:profilPicture">
              </div>
              <div class="address">
                <div class="name">{{message.user.first_name}}</div>
                <div class="date">{{message.created_at_date}} à {{message.created_at_time}}</div>
              </div>
            </div>
            <hr>
            <div class="content">
              <p class="messageContent" style="background: transparent;">{{ message.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <textarea class="textarea" v-model="yourMessage" placeholder="Votre message" rows="8"></textarea>
      <div class="buttons space-between">
        <router-link :to="{name: 'Message'}" tag="a" class="button is-primary" push>Retour</router-link>
        <a class="button is-info" @click="sendMessage" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Envoyer</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_MESSAGE_ACCOUNT, ADD_MESSAGE } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'MessageDetail',

  props: {
    messageId: String
  },
  data () {
    return {
      messages:[],
      yourMessage: '',
      profilPicture: require('./../../../../assets/profil-modal.png'),
      buttonDoneLoad: false,
    }
  },
  methods: {
    sendMessage() {
      this.buttonDoneLoad = true
      // Add Message
      this.$store.dispatch(ADD_MESSAGE, {
        discussionId: this.$props.messageId,
        message: this.yourMessage
      }).then(resp => {
        this.messages.push(resp)
        setTimeout(() => {
          document.querySelector('.message-preview:last-child').scrollIntoView({behavior: "instant", block: "end", inline: "nearest"});
        }, 1000);
        this.yourMessage = ''
        this.buttonDoneLoad = false
      });
    }
  },
  created() {
    // Get Message
    this.$store.dispatch(GET_MESSAGE_ACCOUNT, {
      discussionId: this.$props.messageId
    }).then(resp => {
      this.messages = resp
      setTimeout(() => {
        document.querySelector('.message-preview:last-child').scrollIntoView({behavior: "instant", block: "end", inline: "nearest"});
      }, 1000);
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .message-container {
    max-height: 75vh;
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box ;
  }
  .message-container:before {
    position: sticky;
    content:"";
    background: -moz-linear-gradient(top, rgba(242,245,251,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top, rgba(242,245,251,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(242,245,251,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5fb', endColorstr='#00ffffff',GradientType=0 );
    height: 80px;
    display: block;
    top:0;
    right: 0;
    left: 0;
  }
  .messageContent {
    font-family: monospace;
  }
  .box {
    box-shadow: none;
    border:1px solid #bfc1c5;
  }
  .box.emitter{
    background:rgba(255,255,255,0.5)
  }
  .box.receiver .message-head{
    display: flex;
    justify-content: flex-end;
  }
  .box.receiver .message-head .avatar{
    order: 2;
  }
  .box.receiver .message-head .address{
    text-align: right
  }
  .message-preview .avatar {
    display: inline-block;
  }
  .avatar img {
    width: 40px;
    border-radius: 50px;
    border: 2px solid #999;
    padding: 2px;
  }
  .message-preview .top .address {
    display: inline-block;
    padding: 0 20px;
  }
  .address .name {
    font-size: 16px;
    font-weight: bold;
  }
  .address .date {
    font-weight: bold;
    color: #B6C7D1;
  }
  .content p {
    font-size: 0.9rem;
  }
  hr {
    background-color: #f5f5f5;
    border: none;
    display: block;
    height: 2px;
    margin: 1rem 0;
  }
  .textarea {
    margin-bottom: 1rem;
    box-shadow: none;
  }
  .space-between {
    justify-content: space-between;
    margin-bottom: 1rem;
  }
</style>
